@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item-label > label {
  font-weight: 500;
  font-size: 14px;
  margin: 12px 0 5px 0;
}

.ant-form-item {
  margin-bottom: 2px !important;
  padding: 0px;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #ffffff;
  border: #f3f2f2;
  border-right-width: 10px !important;
  outline: 0;
  -webkit-box-shadow: #ffffff;
  box-shadow: 0 0 0 2px #ffffff;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus, .ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 1px #ff7875;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border: 1px solid #f3f2f2;
  background-color: #ffffff !important;
  text-align: center;
  border-radius: 25px!important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  height: 35px;
  line-height: 2.6!important;
  padding: 4px 8px!important;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    padding-left: 8px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f3f2f2;
  border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
.ant-select-selector {
  border-color: #f3f2f2;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(243, 242, 242, 0.2);
}


.ant-form-large .ant-form-item-label > label {
  height: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #c7c7c7;
  border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  background-color: #f1f1f1 !important;
  height: 40px;

}

.ant-input-affix-wrapper {
  background-color: #f1f1f1 !important;
}

.ant-input {
  border: 1px solid #f3f2f2;
  background-color: #ffffff ;
  text-align: center;
  border-radius: 25px!important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  height: 35px;
  line-height: 2.6!important;
  padding: 4px 8px!important;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    padding-left: 8px;

}

.ant-form-vertical .ant-form-item {
  padding: 2px;
}

.suggestions {
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 999;
  list-style: none;
  border: 1px solid #84c5f0;
}

.suggestion-li {
  padding: 10px;
  text-align: center;
  margin: 0 !important;
}

.ant-btn-primary {
  color: #fff;
  background: #84c5f0;
  border-color: #84c5f0;
  width: 100%;
  height: 37px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ant-radio-checked::after {
  border: 1px solid #ffffff;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #40455a;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #40455a;
}

.ant-radio-inner::after {
  background-color: #40455a;
}

.ant-radio-inner {
    position: absolute;
    top: 30px;
    left: 20px;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: all 0.3s;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #c7c7c7;
  border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  background-color: #f1f1f1 !important;
  height: 40px;
}

.ant-input-affix-wrapper {
  background-color: #f1f1f1 !important;
}

.ant-input:hover {
  border-color: #ffffff;
  border-right-width: 1px !important;
}

.suggestions {
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 999;
  list-style: none;
  border: 1px solid #84c5f0;
  max-height: 150px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.suggestion-item {
  padding: 10px;
  margin: 3px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.suggestion-item:hover {
  cursor: pointer;
  background-color: #84c5f0 !important;
}

.ant-picker {
  background-color: #f1f1f1 !important;
  width: 100% !important;
  max-width: 100% !important;
}

.ant-btn {
  color: #000000d9;
  background: #f1f1f1;
  border-color: #84c5f0;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #000000d9;
  background: #f1f1f1;
  border-color: #84c5f0;
  -webkit-box-shadow: #84c5f033;
  box-shadow: 0 0 0 2px #84c5f033;
}

.ant-dropdown {
  z-index: 800;
}

.ant-btn-dangerous {
  color: #ff4d4f !important;
  background: #fff !important;
  border-color: #ff4d4f !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
  font-size: 10px;
}

.ant-btn-primary {
  width: auto;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0;
  }
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  text-align: justify;
  min-height: 50px;
}

.ant-spin-nested-loading {
  width: 100%;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  border-radius: 35px;
  margin: 0 auto;
  color: rgb(238, 17, 17);
  font-size: 14px;
}

.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #f1f1f1;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding-left: 1px;
  padding-top: 1px;

}

span.ant-radio + * {
    padding-right: 0px;
    padding-left: 0px;
}


.ant-checkbox-inner {
  position: relative;
  top: 10px;
  left: 25;
  display: block;
  width: 22px;
  height: 22px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
  border-radius: 10px;

}

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #c53e5900 ;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #000;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}


.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #000;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #000;
}


.ant-radio-wrapper {
  box-sizing: border-box;
  background-color: rgb(255, 255, 255) ;
  border: 1px solid #f3f2f2;
  border-radius: 28px;
  padding-left: 18px;
  padding-top: 20px;
  width: 95px;
  height: 98px;
  color: #C53E59;
}

.ant-radio-wrapper .ant-radio-wrapper-checked {
  box-sizing: border-box;
  background-color: rgb(223, 29, 29) ;
  border: 1px solid #f3f2f2;
  border-radius: 28px;
  padding-left: 18px;
  padding-top: 20px;
  width: 95px;
  height: 98px;
  color: #C53E59;
}



.ant-form label {
  font-size: 19px;
  line-height: 1;
  text-align: center;
}


.usable-creditcard-form .wrapper {
  border: 1px solid #CCC;
  border-top: 1px solid #AAA;
  border-right: 1px solid #AAA;
  height: 74px;
  width: 300px;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.ant-radio-button-wrapper:first-child:last-child{
  box-sizing: border-box;
  background-color: rgb(255, 255, 255) ;
  border: 1px solid #40455A;
  border-radius: 28px;
  margin: 0 auto;
  padding: 5.5px 19px 3.5px 16px;
  width: 140px;
  height: 90px;
  color: #40455A;
  font-size: 14px;
  padding-top: 30px;
}

.ant-radio-button-wrapper > .ant-radio-button {
  box-sizing: border-box;
  border-radius: 28px;
  margin: 0 auto;
  padding: 5.5px 19px 3.5px 16px;
  color: #fff !important;
  background-color: #40455A;
  font-size: 14px;
  padding-top: 30px;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border: #40455A;
  color: #fff
}

b, strong {
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-size: 14px;
    font-style: normal;
}

p {
  font-family: 'Poppins', sans-serif;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 200;
  text-align: justify;
  font-style: normal;
  font-display: swap;
  line-height: 1.4;
}

button{
  border: none;
  background: none;
}
